import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore as useAuthStore } from '@/stores/auth';
import { RouteName } from './index';
import { useFrameworkEditStore } from '@/stores/framework-edit';

export const frameworkEditGuard = (
    _: RouteLocationNormalized,
    __: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const frameworkEditStore = useFrameworkEditStore();

    if (frameworkEditStore.isDirty) {
        const confirmLeave = window.confirm(
            'You have unsaved changes, Are you sure you want to leave this page?'
        );
        if (!confirmLeave) {
            return next(false);
        }

        frameworkEditStore.cancel();
    }
    next();
};

export const authGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const authStore = useAuthStore();

    if (to.meta.requiresAuth && !authStore.isLoggedIn) {
        next({
            name: RouteName['account.login'],
        });
    } else {
        next();
    }
};
