import { graphql } from '@/gql';
import { useI18n } from 'vue-i18n';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, toRaw, watch } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { useRefHistory } from '@vueuse/core';
import { useFrameworkStore } from '@/stores/framework';
import { useUserSettingsStore } from '@/stores/user-settings';
import { useNotificationStore } from '@/stores/notification';

import type {
    CollectionType,
    Direction,
    FrameworkData,
    HorizontalCollectionType,
} from '@/types/framework';
import type {
    UpdatePortfolioInput,
    UpdatePortfolioItemsInput,
} from '@/gql/graphql';

type ExpandOptions = {
    dir: Direction;
    collection: CollectionType | HorizontalCollectionType;
    repeat: number;
};

export const useFrameworkEditStore = defineStore('framework-edit', () => {
    const { t } = useI18n({
        messages: {
            nl: {
                update_success: 'Aanpassingen zijn opgeslagen',
                update_failure: 'Aanpassingen konden niet worden opgeslagen',
            },
            en: {
                update_success: 'Changes successfully saved',
                update_failure: 'Unable to save changes',
            },
        },
    });

    const userSettingsStore = useUserSettingsStore();
    const notificationStore = useNotificationStore();
    const frameworkStore = useFrameworkStore();
    const { setFrameworkMode } = useFrameworkStore();
    const { framework: originalFramework, mode } = storeToRefs(frameworkStore);

    const _portfolioId = ref<string | undefined>(undefined);
    const axes = ref<FrameworkData['axes'] | null>(null);

    const isDirty = computed(() => {
        if (mode.value !== 'edit') return false;

        if (portfolioName.value !== originalFramework.value.name) {
            return true;
        }

        if (axes.value) {
            return _compareAxes().length > 0;
        }

        return false;
    });

    const differences = computed(() => {
        if (axes.value) {
            return _compareAxes();
        }
        return [];
    });

    const { mutate: updatePortfolioItems } = useMutation(
        graphql(`
            mutation UpdatePortfolioItems($input: UpdatePortfolioItemsInput!) {
                updatePortfolioItems(input: $input) {
                    ok
                }
            }
        `),
        {
            refetchQueries: ['Portfolio', 'PortfolioSelector', 'Framework'],
        }
    );

    async function saveTitles() {
        if (!_portfolioId.value) {
            console.error('Missing required portfolio id');
            return;
        }

        const input = differences.value.map((item) => ({
            id: item.id,
            name: item.modification,
        }));

        return updatePortfolioItems({
            input: {
                portfolioId: _portfolioId.value,
                items: input,
            } satisfies UpdatePortfolioItemsInput,
        });
    }

    function _compareAxes() {
        if (!axes.value) return [];

        const originalAxes = originalFramework.value.axes;

        const differences: {
            id: string;
            original: string;
            modification: string;
        }[] = [];

        const og = [
            ...originalAxes.x.labels,
            ...originalAxes.x.roles,
            ...originalAxes.x.actions,
            ...originalAxes.x.focus,
            ...originalAxes.y.labels,
            ...originalAxes.y.drivers,
            ...originalAxes.y.groups,
            ...originalAxes.y.needs,
        ];

        const modified = [
            ...axes.value.x.labels,
            ...axes.value.x.roles,
            ...axes.value.x.actions,
            ...axes.value.x.focus,
            ...axes.value.y.labels,
            ...axes.value.y.drivers,
            ...axes.value.y.groups,
            ...axes.value.y.needs,
        ];

        modified.forEach((item) => {
            const original = og.find((i) => i.id === item.id);
            if (!original || original.name === item.name) return;

            differences.push({
                original: original.name,
                modification: item.name,
                id: item.id,
            });
        });

        return differences;
    }

    function _getAxesItemById(id: string) {
        if (!axes.value)
            throw Error('Cannot find axes item because axes is undefined');

        const a = [
            ...axes.value.x.roles,
            ...axes.value.x.actions,
            ...axes.value.x.focus,
            ...axes.value.y.drivers,
            ...axes.value.y.groups,
            ...axes.value.y.needs,
        ];
        const item = a.find((i) => i.id === id);

        return item;
    }

    watch(mode, (newVal) => {
        if (newVal === 'edit') {
            setInitialState();
        }
    });

    function setInitialState() {
        portfolioName.value = originalFramework.value.name;
        axes.value = structuredClone(toRaw(originalFramework.value.axes));
        clear();
    }

    const portfolioName = ref<string>('');
    const { undo, clear } = useRefHistory(portfolioName);

    function setPortfolioName(name: string) {
        portfolioName.value = name.trim();
    }

    const { mutate: updatePortfolioName } = useMutation(
        graphql(`
            mutation UpdatePortfolio($input: UpdatePortfolioInput!) {
                updatePortfolio(input: $input) {
                    portfolio {
                        id
                    }
                }
            }
        `),
        {
            refetchQueries: ['Portfolio', 'PortfolioSelector'],
        }
    );

    async function savePortfolioName() {
        if (!_portfolioId.value) {
            console.error('Missing required portfolio id');
            return;
        }

        return updatePortfolioName({
            input: {
                portfolioId: _portfolioId.value,
                name: portfolioName.value.trim(),
            } satisfies UpdatePortfolioInput,
        });
    }

    async function saveChanges(portfolioId: string) {
        _portfolioId.value = portfolioId;

        try {
            if (portfolioName.value !== originalFramework.value.name) {
                await savePortfolioName();

                userSettingsStore.setActivePortfolio({
                    name: portfolioName.value.trim(),
                    id: userSettingsStore.activePortfolio?.id as string,
                });

                notificationStore.add({
                    type: 'success',
                    message: t('update_success'),
                });
            }
            if (differences.value.length) {
                await saveTitles();

                notificationStore.add({
                    type: 'success',
                    message: t('update_success'),
                });
            }
        } catch (err) {
            console.error(err);
            notificationStore.add({
                type: 'error',
                message: t('update_failure'),
            });
        } finally {
            finalizeEdit();
        }
    }

    function cancel() {
        setInitialState();
        setFrameworkMode('view');
    }

    function finalizeEdit() {
        setFrameworkMode('view');
        setInitialState();
        clear();
    }

    function expand(id: string, options: ExpandOptions) {
        const { dir, collection, repeat } = options;

        if (!axes.value) throw Error(`Axes undefined`);

        let target = axes.value?.x.roles;
        if (collection === 'role') target = axes.value?.x.roles;
        if (collection === 'group') target = axes.value?.y.groups;
        if (collection === 'driver') target = axes.value?.y.drivers;

        if (!target) {
            throw Error(
                `Unable to expand: collection ${collection} not found.`
            );
        }

        // Expand horizontally
        if (['left', 'right'].includes(dir)) {
            expandRole(id, dir as 'left' | 'right');
        }

        if (['up', 'down'].includes(dir)) {
            if (collection === 'driver') expandDriver(id, dir as 'up' | 'down');
            if (collection === 'group') expandGroup(id, dir as 'up' | 'down');
        }

        if (repeat > 1) {
            expand(id, {
                collection,
                dir,
                repeat: repeat - 1,
            });
        }
    }

    function expandDriver(id: string, dir: 'up' | 'down') {
        if (!axes.value) throw Error(`Axes undefined`);
        const index = axes.value.y.drivers.findIndex((d) => d.id === id);
        // Dragging on vertical axis
        const increment = dir === 'up' ? -1 : 1;
        const nextItemIndex = index + increment;
        const nextItemId = axes.value.y.drivers[nextItemIndex].id;
        if (!nextItemId) throw Error(`No ${dir} collection found`);

        const nextNeeds = axes.value.y.needs.filter(
            (need) => need.driverId === nextItemId
        );

        if (nextNeeds.length <= 1) return;
        if (!nextNeeds.length) return;

        const nextNeed =
            dir === 'down' ? nextNeeds[0] : nextNeeds[nextNeeds.length - 1];
        nextNeed.driverId = id;
    }

    function expandGroup(id: string, dir: 'up' | 'down') {
        if (!axes.value) throw Error(`Axes undefined`);
        const index = axes.value.y.groups.findIndex((d) => d.id === id);
        // Dragging on vertical axis
        const increment = dir === 'up' ? -1 : 1;
        const nextItemIndex = index + increment;
        const nextItemId = axes.value.y.drivers[nextItemIndex].id;
        if (!nextItemId) throw Error(`No ${dir} collection found`);

        const nextNeeds = axes.value.y.needs.filter(
            (need) => need.driverId === nextItemId
        );

        if (nextNeeds.length <= 1) return;
        if (!nextNeeds.length) return;

        const nextNeed =
            dir === 'down' ? nextNeeds[0] : nextNeeds[nextNeeds.length - 1];
        nextNeed.driverId = id;
    }

    function expandRole(id: string, dir: 'left' | 'right') {
        if (!axes.value?.x) throw Error(`x-axis is undefined`);
        const index = axes.value.x.roles.findIndex((r) => r.id === id);

        // Prevent expanding at edges.
        if (dir === 'left' && index === 0) return;
        if (dir === 'right' && index === axes.value.x.roles.length - 1) return;

        const increment = dir === 'left' ? -1 : 1;
        const nextIndex = index + increment;
        if (!axes.value.x.roles[nextIndex])
            throw Error(`No ${dir} collection found`);
        const nextId = axes.value.x.roles[nextIndex].id;

        const nextChildren = axes.value.x.actions.filter(
            (action) => action.roleId === nextId
        );

        // Prevent sibling role from running out of actions.
        if (nextChildren.length <= 1 || !nextChildren.length) return;

        const childToAdd =
            dir === 'right'
                ? nextChildren[0]
                : nextChildren[nextChildren.length - 1];

        childToAdd.roleId = id;
    }

    function setItemName(
        type:
            | 'roles'
            | 'actions'
            | 'focus'
            | 'drivers'
            | 'groups'
            | 'needs'
            | 'label_x'
            | 'label_y',
        id: string,
        value: string
    ) {
        const typeToListMap = {
            label_x: axes.value?.x.labels,
            roles: axes.value?.x.roles,
            actions: axes.value?.x.actions,
            focus: axes.value?.x.focus,
            label_y: axes.value?.y.labels,
            drivers: axes.value?.y.drivers,
            groups: axes.value?.y.groups,
            needs: axes.value?.y.needs,
        };

        const targetList = typeToListMap[type];
        if (!targetList) throw Error(`Invalid type: ${type}`);

        const target = targetList.find((t) => t.id === id);
        if (!target) throw Error(`Unable to find id ${id} in ${type}`);

        target.name = value;
    }

    return {
        axes,
        differences,
        isDirty,
        portfolioName,
        cancel,
        expand,
        saveChanges,
        setInitialState,
        setItemName,
        setPortfolioName,
        undo,
    };
});
